<template>
  <div>
    <res-info ref="resInfo"></res-info>
    <div class="form-btn-view">
      <van-row class="btnPosition" v-if="disabled">
        <van-col span="12">
          <van-button class="btnSize ww" @click="editHandle">编辑</van-button>
        </van-col>
        <van-col span="12">
          <van-button class="btnSize ww" style="background-color:#EA3B28" @click="moveOut">迁出</van-button>
        </van-col>
      </van-row>
      <van-row class="btnPosition" v-else>
        <van-col span="24">
          <van-button round block type="info" native-type="submit" class="btnSize ww"
                      v-if="!disabled && !id">确认添加
          </van-button>
          <van-button round block type="info" native-type="submit" class="btnSize ww"
                      v-if="!disabled && id" @click="omSubmit">确认保存
          </van-button>
        </van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
import resInfo from './resInfo.vue'
export default {
  components: {
    resInfo
  },
  data () {
    return {
      id: '',
      community: '',
      disabled: true
    }
  },
  mounted () {
    let options = this.$route.query
    this.id = options.userId || ''
    this.community = options.community || ''
    this.disabled = this.id ? true : false
    this.$refs.resInfo.init(options, true)
  },
  methods: {
    omSubmit () {
      this.$dialog.confirm({
        message: '确认保存吗'
      }).then( ()=> {
        this.$http({
          url: this.$http.adornUrl('/wxapp/user/save'),
          method: 'post',
          data: this.$http.adornData({
            ...this.$refs.resInfo.dataForm,
            labels: this.$refs.resInfo.dataForm.labels.join(','),
          })
        }).then(({data})=> {
          if (data.code == 0) {
            this.$toast.success('操作成功')
            this.$router.back()
          } else{
            this.$toast.fail(data.msg)
          }
          this.$toast.clear()
        },err=> {this.$toast.fail(err)})
      })
    },
    editHandle () {
      this.disabled = false
      this.$refs.resInfo.control.disabled = false
    },
    moveOut () {
      this.$dialog.confirm({
        message: '确认迁出吗'
      }).then( ()=> {
        this.$http({
          url: this.$http.adornUrl('/wxapp/user/res/moveOutCommunity'),
          method: 'post',
          params: this.$http.adornParams({
            id: this.id,
            community: this.$orgId
          })
        }).then(({data})=> {
          if (data.code == 0) {
            this.$toast.success('操作成功')
            this.$router.back()
          } else{
            this.$toast.fail(data.msg)
          }
          this.$toast.clear()
        },err=> {this.$toast.fail(err)})
      })
    }
  }
}
</script>

<style scoped>
.btnPosition {
  position: absolute;
  bottom: 20px;
  width: 720px;
  left: 15px;
}
.ww{
  font-size: 40px !important;
  width:100%;
}
</style>
